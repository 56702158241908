import service from '@/utils/request';

// @Tags SysTeamMember
// @Summary 创建SysTeamMember
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysTeamMember true "创建SysTeamMember"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysTeamMember/createSysTeamMember [post]
export var createSysTeamMember = function createSysTeamMember(data) {
  return service({
    url: "/sysTeamMember/createSysTeamMember",
    method: 'post',
    data: data
  });
};

// @Tags SysTeamMember
// @Summary 删除SysTeamMember
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysTeamMember true "删除SysTeamMember"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /sysTeamMember/deleteSysTeamMember [delete]
export var deleteSysTeamMember = function deleteSysTeamMember(data) {
  return service({
    url: "/sysTeamMember/deleteSysTeamMember",
    method: 'delete',
    data: data
  });
};

// @Tags SysTeamMember
// @Summary 删除SysTeamMember
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除SysTeamMember"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /sysTeamMember/deleteSysTeamMember [delete]
export var deleteSysTeamMemberByIds = function deleteSysTeamMemberByIds(data) {
  return service({
    url: "/sysTeamMember/deleteSysTeamMemberByIds",
    method: 'delete',
    data: data
  });
};

// @Tags SysTeamMember
// @Summary 更新SysTeamMember
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysTeamMember true "更新SysTeamMember"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /sysTeamMember/updateSysTeamMember [put]
export var updateSysTeamMember = function updateSysTeamMember(data) {
  return service({
    url: "/sysTeamMember/updateSysTeamMember",
    method: 'put',
    data: data
  });
};

// @Tags SysTeamMember
// @Summary 更新权限
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysTeamMember true "更新权限"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /sysTeamMember/updateAuthority [put]

export var updateAuthority = function updateAuthority(data) {
  return service({
    url: "/sysTeamMember/updateAuthority",
    method: 'put',
    data: data
  });
};

// @Tags SysTeamMember
// @Summary 更新权限
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysTeamMember true "更新权限"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /sysTeamMember/updateAuthority [put]

export var updateDefaultTeam = function updateDefaultTeam(data) {
  return service({
    url: "/sysTeamMember/updateDefaultTeam",
    method: 'put',
    data: data
  });
};

// @Tags SysTeamMember
// @Summary 用id查询SysTeamMember
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysTeamMember true "用id查询SysTeamMember"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /sysTeamMember/findSysTeamMember [get]
export var findSysTeamMember = function findSysTeamMember(params) {
  return service({
    url: "/sysTeamMember/findSysTeamMember",
    method: 'get',
    params: params
  });
};

// @Tags SysTeamMember
// @Summary 分页获取SysTeamMember列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取SysTeamMember列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysTeamMember/getSysTeamMemberList [get]
export var getSysTeamMemberList = function getSysTeamMemberList(params) {
  return service({
    url: "/sysTeamMember/getSysTeamMemberList",
    method: 'get',
    params: params
  });
};

// @Tags SysTeamMember
// @Summary 获取未添加team的user
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "获取未添加team的user"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysTeamMember/getFreeUserList [get]
export var getFreeUserList = function getFreeUserList(params) {
  return service({
    url: "/sysTeamMember/getFreeUserList",
    method: 'get',
    donNotShowLoading: true,
    params: params
  });
};

// @Tags SysTeamMember
// @Summary 获取用户的team
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "获取用户的team"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysTeamMember/getTeamsByUser [get]
export var getTeamsByUser = function getTeamsByUser(params) {
  return service({
    url: "/sysTeamMember/getTeamsByUser",
    method: 'get',
    params: params
  });
};

// @Tags SysTeamMember
// @Summary 邀请SysTeamMember
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysTeamMember true "邀请SysTeamMember"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"邀请成功"}"
// @Router /sysTeamMember/InviteSysTeamMember [post]
export var inviteSysTeamMember = function inviteSysTeamMember(data) {
  return service({
    url: "/sysTeamMember/inviteSysTeamMember",
    method: 'post',
    data: data
  });
};

// @Tags SysTeamMember
// @Summary EmailConfirm成员邀请邮件校验
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body code true "验证加密信息"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"验证成功"}"
// @Router /base/emailConfirm [post]
export var emailConfirm = function emailConfirm(data) {
  return service({
    url: "/base/emailConfirm",
    method: 'post',
    data: data
  });
};